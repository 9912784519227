import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: "",
};

const displayedGroupIdSlice = createSlice({
  name: "displayedGroupId",
  initialState,
  reducers: {
    changeId(state, action) {
      state.item = action.payload;
    },
    deleteId(state, action) {
      state.item = "";
    },
  },
});

export const { changeId, deleteId } = displayedGroupIdSlice.actions;
export default displayedGroupIdSlice.reducer;
