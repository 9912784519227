import AxiosPost from "../../Functions/AxiosPost";
import { changeItem } from "../../reducers/groupsInfoSlice";

function GroupsInfo(idToken, dispatch, openResponse) {
  const getData = async () => {
    const response = await AxiosPost(
      "/list_groups_of_manager",
      {},
      idToken,
      openResponse
    );
    dispatch(changeItem(response.data.groups));
    // const response = {
    //   data: {
    //     groups: [
    //       {
    //         groupId: "group01",
    //         groupName: "森のグループ11",
    //         numOfManagers: 2,
    //         numOfDrivers: 8,
    //         description: "hey",
    //         isWaitingApproval: true,
    //       },
    //       {
    //         groupId: "group02",
    //         groupName: "森のグループ2",
    //         numOfManagers: 0,
    //         numOfDrivers: 0,
    //         description: "hey",
    //         isWaitingApproval: false,
    //       },
    //       {
    //         groupId: "group04",
    //         groupName: "森のグループ4",
    //         numOfManagers: 3,
    //         numOfDrivers: 2,
    //         description: "hey",
    //         isWaitingApproval: false,
    //       },
    //     ],
    //   },
    // };
    return response;
  };
  return getData();
}

export default GroupsInfo;
