import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import GroupNamesInfo from "../components/Data/GroupNamesInfo";
import getIdToken from "../Functions/getIdToken";

import store from "../store";
import { deleteToken } from "../reducers/idTokenSlice";
import { resetGroupNames } from "../reducers/groupNamesInfoSlice";
import { resetGroupsInfo } from "../reducers/groupsInfoSlice";
import { resetDriversInfo } from "../reducers/driversInfoSlice";
import { resetUnapprovedInfo } from "../reducers/unapprovedDriversInfoSlice";
import { resetManagersInfo } from "../reducers/managersInfoSlice";
import { deleteUserName } from "../reducers/userNameInfoSlice";
import { deleteId } from "../reducers/displayedGroupIdSlice";
import "../css/managerui.css";

import hota from "../img/ホー太くんねないホー.png";

function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = location.pathname;
  const [toSignOut, setToSignOut] = React.useState(false);
  const closeDialog = () => {
    setToSignOut(false);
  };
  const [idToken, setIdToken] = React.useState(store.getState().idToken.item);
  const [groupNamesList, setGroupNamesList] = React.useState(
    store.getState().groupNamesInfo.items
  );
  React.useEffect(() => {
    const fetchData = async (token) => {
      const response = await GroupNamesInfo(token, dispatch);
      return response;
    };
    getIdToken(idToken, setIdToken, dispatch, navigate).then((token) => {
      if (token && !groupNamesList[0]) {
        fetchData(token).then((res) => {
          setGroupNamesList(res);
        });
      }
    });
  }, [idToken, path]);
  const signOut = async () => {
    closeDialog();
    await Auth.signOut().then(() => {
      setIdToken("");
      dispatch(resetGroupNames());
      dispatch(resetGroupsInfo());
      dispatch(resetDriversInfo());
      dispatch(resetUnapprovedInfo());
      dispatch(resetManagersInfo());
      dispatch(deleteUserName());
      dispatch(deleteId());
      dispatch(deleteToken());
      navigate("/");
    });
  };
  const [drawerState, setDrawerState] = React.useState(false);
  const openDrawer = () => {
    setDrawerState(true);
  };
  const closeDrawer = () => {
    setDrawerState(false);
  };
  const toGroupList = () => {
    setDrawerState(false);
    navigate("/grouplist/");
  };
  const transferGroup = (index) => {
    setDrawerState(false);
    navigate(
      "/drivermanagerlist/" +
        groupNamesList[index].groupId +
        "/driver" +
        "?name=" +
        groupNamesList[index].groupName
    );
  };
  const navigateToAccount = () => {
    const prePath = location.pathname;
    navigate("/accountinfo");
  };
  return (
    <div>
      <Grid container>
        <Box
          component="span"
          sx={{
            p: 0,
            m: 2,
            backgroundColor: "#86c3f8dd",
            borderRadius: 4,
            width: "100%",
            minWidth: "1000px",
            height: "110px",
          }}
        >
          <Grid container>
            {idToken ? (
              <Grid item xs={0.5}>
                <MenuIcon
                  onClick={() => openDrawer()}
                  className="pushIcon"
                  fontSize="large"
                  sx={{ ml: 2, mt: 5 }}
                />
                <DrawerList
                  open={drawerState}
                  close={closeDrawer}
                  toGroupList={toGroupList}
                  groupNames={groupNamesList}
                  transferGroup={transferGroup}
                />
              </Grid>
            ) : (
              <Grid></Grid>
            )}
            <Grid item xs={1}>
              <img className="hota" src={hota} width="80px" height="80px" />
            </Grid>
            <Grid item xs={3}>
              <p sx={{ m: 1 }} className="headerText">
                ねないホー
              </p>
              <p className="headerSubText">ドライバー健康管理システム</p>
            </Grid>
            <div style={{ flexGrow: 1 }}></div>
            <Grid item>
              <Button
                variant="outlined"
                disabled={!idToken}
                onClick={() => {
                  navigateToAccount();
                }}
                size="small"
                sx={{ mt: 9, mr: 2 }}
              >
                登録情報
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                disabled={!idToken}
                onClick={() => {
                  setToSignOut(true);
                }}
                size="small"
                sx={{ mt: 9, mr: 2 }}
              >
                ログアウト
              </Button>
              <Dialog
                open={toSignOut}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText>
                    <p className="confirmText" align="center">
                      ログアウトしますか？
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={signOut}>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
}

function DrawerList(props) {
  return (
    <Drawer anchor="left" open={props.open} onClose={props.close}>
      <Box sx={{ width: 200 }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => props.toGroupList()}>
              <p>グループ一覧へ</p>
            </ListItemButton>
          </ListItem>
          {props.groupNames.map((group, index) => (
            <ListItem key={index}>
              <ListItemButton onClick={() => props.transferGroup(index)}>
                <p>{group.groupName}</p>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default Header;
