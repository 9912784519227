import AxiosPost from "../../Functions/AxiosPost";
import { changeItem } from "../../reducers/managersInfoSlice";

function ManagersInfo(groupId, idToken, dispatch, openResponse) {
  const getData = async () => {
    const response = await AxiosPost(
      "/list_managers_of_group",
      {
        groupId: groupId,
      },
      idToken,
      openResponse
    );
    // const response = { data: { groupManagers: [{}] } };
    dispatch(changeItem(response.data.groupManagers));
    return response.data.groupManagers;
  };
  return getData();
}

export default ManagersInfo;
