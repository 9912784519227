import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";

import "./css/managerui.css";

import Header from "./components/Header";
import SignIn from "./pages/SignIn";
import GroupList from "./pages/GroupList";
import DriverManagerList from "./pages/DriverManagerList";
import AccountInfo from "./pages/AccountInfo";

const Theme = createTheme({
  palette: {
    textPrimary: {
      main: "#ffffff",
    },
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#00bfff",
    },
    allowed: {
      main: "#00bfff",
      light: "#00bfff",
      dark: "#00bfff",
      contrastText: "#000000",
    },
    alert: {
      main: "#ff0000",
      light: "#ff0000",
      dark: "#ff0000",
      contrastText: "#ffffff",
    },
    active: {
      main: "#32cd32",
      light: "#32cd32",
      dark: "#32cd32",
      contrastText: "#ffffff",
    },
    absent: {
      main: "#a9a9a9",
      light: "#a9a9a9",
      dark: "#a9a9a9",
      contrastText: "#ffffff",
    },
  },
});

function App() {
  return (
    <div className="background">
      <ThemeProvider theme={Theme}>
        <Router>
          <Header />
          <Routes>
            <Route path="" element={<SignIn />} />
            <Route path="grouplist/" element={<GroupList />} />
            <Route
              path="drivermanagerlist/:groupId/:which"
              element={<DriverManagerList />}
            />
            <Route path="accountinfo" element={<AccountInfo />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
