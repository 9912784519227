import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: "",
};

const userNameInfoSlice = createSlice({
  name: "userNameInfo",
  initialState,
  reducers: {
    changeItem(state, action) {
      const data = action.payload;
      state.item = data;
    },
    deleteUserName(state, action) {
      state.item = "";
    },
  },
});

export const { changeItem, deleteUserName } = userNameInfoSlice.actions;
export default userNameInfoSlice.reducer;
