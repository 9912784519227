import React from "react";
import "../../css/managerui.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

function EditNameDialog(props) {
  const [inputName, setInputName] = React.useState(props.preName);
  const handleName = (event) => {
    setInputName(event.target.value);
  };
  return (
    <Dialog
      open={props.isEditable}
      onClose={() => {
        props.closeEditDialog();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle sx={{ mb: -2 }}>
        <p>名前の変更</p>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <TextField
              autoFocus
              id="standard-basic"
              label=""
              variant="standard"
              value={inputName}
              required
              onChange={(event) => handleName(event)}
            />
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          sx={{ mt: -2 }}
          onClick={() => props.changeName(inputName)}
        >
          変更
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditNameDialog;
