import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const groupNamesInfoSlice = createSlice({
  name: "groupNamesInfo",
  initialState,
  reducers: {
    changeItem(state, action) {
      const data = action.payload;
      state.items = [];
      data.map((item) => {
        state.items = [...state.items, { ...item }];
      });
    },
    resetGroupNames(state, action) {
      state.items = [];
    },
    // groupNamesはmanagerに対して固定なはず
  },
});

export const { changeItem, resetGroupNames } = groupNamesInfoSlice.actions;
export default groupNamesInfoSlice.reducer;
