import React from "react";
import MaterialTable from "material-table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import AxiosPost from "../../Functions/AxiosPost";
import tableIcons from "../../Settings/TableIcons";
import localizationJapanese from "../../Settings/ToJapanese";

function UnapprovedDriverTable(props) {
  const [processDialog, setProcessDialog] = React.useState(false);
  const [driverToProcess, setDriverToProcess] = React.useState({
    toApprove: true,
  });
  const processUnapprovedDriver = (rowData, toApprove) => {
    if (toApprove) {
      rowData.toApprove = true;
    } else {
      rowData.toApprove = false;
    }
    setDriverToProcess(rowData);
    if (driverToProcess) {
      setProcessDialog(true);
    }
  };
  const closeProcessDialog = () => {
    setProcessDialog(false);
  };
  const process = async () => {
    setProcessDialog(false);
    props.setIsLoading(true);
    if (driverToProcess.toApprove) {
      await AxiosPost(
        "/approve_driver",
        {
          driverId: driverToProcess.driverId,
          groupId: props.groupId,
        },
        props.idToken
      ).then((response) => {
        if (response) {
          props.modifyUnapprovedDriver({
            driverId: driverToProcess.driverId,
            process: "approve",
          });
        }
      });
    } else {
      await AxiosPost(
        "/reject_driver",
        {
          driverId: driverToProcess.driverId,
          groupId: props.groupId,
        },
        props.idToken
      ).then((response) => {
        if (response) {
          props.modifyUnapprovedDriver({
            driverId: driverToProcess.driverId,
            process: "reject",
          });
        }
      });
    }
  };
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        icons={tableIcons}
        title=""
        columns={unapprovedDriverTableColumns(processUnapprovedDriver)}
        data={props.unapprovedDriversList}
        options={{
          rowStyle: {
            backgroundColor: "#EEE",
          },
        }}
        localization={localizationJapanese}
      />
      <Dialog
        open={processDialog}
        onClose={closeProcessDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            {driverToProcess.toApprove ? (
              <p className="confirmText" align="center" sx={{ m: 1 }}>
                {driverToProcess.driverShowName}を承認しますか？
              </p>
            ) : (
              <p className="confirmText" align="center">
                {driverToProcess.driverShowName}を拒否しますか？
              </p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={() => process()}>
            OK
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => closeProcessDialog()}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function unapprovedDriverTableColumns(processUnapprovedDriver) {
  const columns = [
    {
      title: "ドライバー名",
      field: "driverShowName",
      type: "string",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "社員ID",
      field: "driverShowNumber",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "申請日",
      field: "appliedAt",
      type: "string",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "",
      field: "action",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <Grid>
          <Button
            variant="contained"
            size="small"
            onClick={() => processUnapprovedDriver(rowData, true)}
            sx={{ mr: 1, mt: -1, mb: -1 }}
            color="success"
          >
            <CheckCircleIcon fontSize="small" />
            <p className="buttonText">承認</p>
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => processUnapprovedDriver(rowData, false)}
            sx={{ mt: -1, mb: -1 }}
            color="error"
          >
            <CancelIcon fontSize="small" />
            <p className="buttonText">拒否</p>
          </Button>
        </Grid>
      ),
    },
  ];
  return columns;
}

export default UnapprovedDriverTable;
