import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const managersInfoSlice = createSlice({
  name: "managersInfo",
  initialState,
  reducers: {
    changeItem(state, action) {
      const data = action.payload;
      state.items = [];
      data.map((item) => {
        state.items = [...state.items, { ...item }];
      });
    },
    addItem(state, action) {
      state.items = [...state.items, { ...action.payload }];
    },
    deleteItem(state, action) {
      state.items = state.items.filter(
        (item) => item.managerId !== action.payload.managerId
      );
    },
    resetManagersInfo(state, action) {
      state.items = [];
    },
  },
});

export const { changeItem, addItem, deleteItem, resetManagersInfo } =
  managersInfoSlice.actions;
export default managersInfoSlice.reducer;
