import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import "../../css/managerui.css";
import hota from "../../img/ホー太くんねないホー.png";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { deleteToken } from "../../reducers/idTokenSlice";
import { resetGroupNames } from "../../reducers/groupNamesInfoSlice";
import { resetGroupsInfo } from "../../reducers/groupsInfoSlice";
import { resetDriversInfo } from "../../reducers/driversInfoSlice";
import { resetUnapprovedInfo } from "../../reducers/unapprovedDriversInfoSlice";
import { resetManagersInfo } from "../../reducers/managersInfoSlice";
import { deleteUserName } from "../../reducers/userNameInfoSlice";
import { deleteId } from "../../reducers/displayedGroupIdSlice";

function ResponseDialog(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOut = async () => {
    await Auth.signOut().then(() => {
      dispatch(resetGroupNames());
      dispatch(resetGroupsInfo());
      dispatch(resetDriversInfo());
      dispatch(resetUnapprovedInfo());
      dispatch(resetManagersInfo());
      dispatch(deleteUserName());
      dispatch(deleteId());
      dispatch(deleteToken());
      navigate("/");
    });
  };
  return (
    <div>
      {!props.dialogText ? (
        <Dialog
          open={props.isDialog}
          onClose={() => {
            props.closeDialog();
          }}
          fullWidth={false}
          maxWidth="xs"
        >
          <DialogTitle sx={{ mt: 3, mb: 3, mr: 1, ml: 1 }}>
            <img className="dialogHota" src={hota} width="45px" height="45px" />
            通信にエラーが発生しました
            <img className="dialogHota" src={hota} width="45px" height="45px" />
          </DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: -2 }}
              onClick={() => {
                window.location.reload();
              }}
            >
              リロード
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: -2 }}
              onClick={() => {
                signOut();
              }}
            >
              ログアウト
            </Button>
          </DialogActions>
        </Dialog>
      ) : props.dialogText === 453 ? (
        <Dialog open={props.isDialog} fullWidth={false} maxWidth="xs">
          <DialogTitle sx={{ mt: 3, mb: 3, mr: 1, ml: 1 }}>
            <img className="dialogHota" src={hota} width="45px" height="45px" />
            アクセス権がありません
            <img className="dialogHota" src={hota} width="45px" height="45px" />
          </DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: -2 }}
              onClick={() => {
                navigate("/grouplist");
              }}
            >
              グループ一覧へ
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: -2 }}
              onClick={() => {
                signOut();
              }}
            >
              ログアウト
            </Button>
          </DialogActions>
        </Dialog>
      ) : props.dialogText === 403 ? (
        <Dialog open={props.isDialog} fullWidth={true} maxWidth="xs">
          <DialogTitle>
            <img className="dialogHota" src={hota} width="45px" height="45px" />
            エラーが発生しました。
            <p className="errorText">下記までお問い合わせください。</p>
            <p className="errorText">メールアドレス: ab@medaka-college.com</p>
          </DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: -2 }}
              onClick={() => {
                window.location.reload();
              }}
            >
              再読み込み
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={props.isDialog}
          onClose={() => {
            props.closeDialog();
          }}
          fullWidth={false}
          maxWidth="xs"
        >
          <DialogTitle sx={{ mr: 1, ml: 1 }}>
            <img className="dialogHota" src={hota} width="45px" height="45px" />
            エラーが発生しました。
          </DialogTitle>
          <Box sx={{ mb: 2 }}>
            <div className="centerText">
              <span className="dialogText">{props.dialogText}</span>
            </div>
          </Box>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: -2 }}
              onClick={() => {
                props.closeDialog();
                props.setIsLoading(false);
              }}
            >
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default ResponseDialog;
