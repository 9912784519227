import React from "react";
import { useNavigate } from "react-router-dom";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";
import vocabularies from "../Settings/AmplifyTranslation";
import "@aws-amplify/ui-react/styles.css";

import Button from "@mui/material/Button";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

function Signin() {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = React.useState("");
  React.useEffect(() => {
    Auth.currentSession()
      .then(() => {
        navigate("/grouplist");
      })
      .catch(() => {
        console.log("ログアウト中");
      });
  }, [authUser]);

  const pushButton = async () => {
    navigate("/grouplist");
  };

  return (
    <div>
      <Authenticator>
        {({ user }) => {
          setAuthUser(user);
          return (
            <main>
              <p>ページが移動しない場合、下のボタンを押してください</p>
              <Button variant="outlined" onClick={pushButton}>
                次へ
              </Button>
            </main>
          );
        }}
      </Authenticator>
    </div>
  );
}
export default Signin;

I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");
