import React from "react";
import "../../css/managerui.css";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import AxiosPost from "../../Functions/AxiosPost";

import { modifyDriverData } from "../../reducers/driversInfoSlice";
import { modifyGroupData } from "../../reducers/groupsInfoSlice";

function ModificationDialog(props) {
  const [isLong, setIsLong] = React.useState(false);
  const modifyDescription = async () => {
    if (props.description.length > 100) {
      setIsLong(true);
      props.setIsLoading(true);
    } else {
      props.closeModificationDialog();
      setIsLong(false);
      let response = false;
      if (props.table === "group") {
        if (props.information.operation === "description") {
          console.log(props.information.content.groupId);
          const modifiedItem = {
            key: "description",
            groupId: props.information.content.groupId,
            description: props.description,
          };
          await AxiosPost(
            "/modify_group_description",
            modifiedItem,
            props.idToken,
            props.openResponseDialog
          ).then(() => {
            props.dispatch(modifyGroupData(modifiedItem));
            props.modifyData(modifiedItem);
          });
        } else if (props.information.operation === "name") {
          const modifiedItem = {
            key: "groupName",
            groupId: props.information.content.groupId,
            groupName: props.name,
          };
          await AxiosPost(
            "/modify_group_name",
            modifiedItem,
            props.idToken,
            props.openResponseDialog
          ).then(() => {
            props.dispatch(modifyGroupData(modifiedItem));
            props.modifyData(modifiedItem);
          });
        }
      } else if (props.table === "driver") {
        const modifiedItem = {
          key: "description",
          driverId: props.information.content.driverId,
          groupId: props.information.content.groupId,
          description: props.description,
        };
        response = await AxiosPost(
          "/modify_driver_description",
          modifiedItem,
          props.idToken,
          props.openResponseDialog
        );
        props.dispatch(modifyDriverData(modifiedItem));
        props.modifyDriver(modifiedItem);
      }
      props.setIsLoading(false);
    }
  };
  return (
    <Dialog
      open={props.modificationDialog}
      onClose={() => {
        setIsLong(false);
        props.closeModificationDialog();
      }}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle sx={{ mb: -2 }}>
        {props.table === "group" && props.information.operation === "name" ? (
          <p>{props.information.name}の名前の編集</p>
        ) : (
          <p>{props.information.name}のメモの編集</p>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText justifyContent="center">
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {props.information.operation === "description" ? (
                <TextField
                  id="description"
                  value={props.description}
                  onChange={(event) => props.handleDescriptionToModify(event)}
                  multiline
                  rows={3}
                  label=""
                  sx={{ width: "100%" }}
                />
              ) : props.information.operation === "name" ? (
                <TextField
                  id="name"
                  value={props.name}
                  onChange={(event) => props.handleNameToModify(event)}
                  multiline
                  rows={3}
                  label=""
                  sx={{ width: "100%" }}
                />
              ) : (
                <Grid />
              )}
            </Grid>
          </Grid>
          {isLong ? (
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <p className="alertText">100字以下にしてください</p>
              </Grid>
            </Grid>
          ) : (
            <Grid />
          )}
          <Grid container justifyContent="flex-end" className="margin5">
            <Grid item xs={3}>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                size="medium"
                onClick={() => {
                  modifyDescription();
                }}
              >
                <SaveAltIcon fontSize="small" />
                <p className="buttonText">変更</p>
              </Button>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          sx={{ mt: -2 }}
          onClick={() => {
            props.closeModificationDialog();
          }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModificationDialog;
