import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const unapprovedDriversInfoSlice = createSlice({
  name: "unapprovedDriversInfo",
  initialState,
  reducers: {
    changeItem(state, action) {
      const data = action.payload;
      state.items = [];
      data.map((item) => {
        state.items = [...state.items, { ...item }];
      });
    },
    addItem(state, action) {
      state.items = [...state.items, { ...action.payload }];
    },
    deleteItem(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    resetUnapprovedInfo(state, action) {
      state.items = [];
    },
  },
});

export const { changeItem, addItem, deleteItem, resetUnapprovedInfo } =
  unapprovedDriversInfoSlice.actions;
export default unapprovedDriversInfoSlice.reducer;
