import AxiosPost from "../../Functions/AxiosPost";
import { changeItem } from "../../reducers/groupNamesInfoSlice";

async function GroupNamesInfo(idToken, dispatch) {
  const responseDialog = () => {
    console.log("エラー出てる");
  };
  const response = await AxiosPost(
    "/list_group_names_of_manager",
    {},
    idToken,
    responseDialog
  );
  dispatch(changeItem(response.data.groups));
  return response.data.groups;
}

export default GroupNamesInfo;
