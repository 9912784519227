import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import store from "../store";
import "../css/managerui.css";

import ResponseDialog from "../components/Dialogs/ResponseDialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import GroupTable from "../components/Tables/GroupTable";
import getIdToken from "../Functions/getIdToken";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";
import vocabularies from "../Settings/AmplifyTranslation";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
Amplify.configure(awsExports);

function GroupList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [idToken, setIdToken] = React.useState(store.getState().idToken.item);
  const [isDialog, setIsDialog] = React.useState(false);
  const [dialogText, setDialogText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const openDialog = (message) => {
    setDialogText(message);
    setIsDialog(true);
  };
  const closeDialog = () => {
    setIsDialog(false);
  };
  React.useEffect(() => {
    getIdToken(idToken, setIdToken, dispatch, navigate);
  }, []);
  return (
    <div>
      <Authenticator>
        <Grid container className="container">
          <Grid container>
            <Grid item>
              <Box sx={{ minWidth: "500px" }}>
                <p className="title">管理グループ一覧</p>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Box
              component="span"
              sx={{
                m: 2,
                width: "100%",
                minWidth: "950px",
              }}
            >
              <Grid container className="container" justifyContent="center">
                <Grid item xs={12}>
                  <GroupTable
                    idToken={idToken}
                    openResponseDialog={openDialog}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <ResponseDialog
          isDialog={isDialog}
          closeDialog={closeDialog}
          dialogText={dialogText}
          setIsLoading={setIsLoading}
        />
      </Authenticator>
    </div>
  );
}

export default GroupList;

I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");
