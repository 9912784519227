import axios from "axios";
import errorCodeTranslation from "../Settings/AlertTranslation";

export default async function AxiosPost(path, body, idToken, openResponse) {
  try {
    const url =
      "https://4x3nbthjui.execute-api.ap-northeast-1.amazonaws.com/Devo/manager_ui" +
      path;
    const headers = { headers: { Authorization: idToken } };
    const response = await axios.post(url, body, headers);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const message = errorCodeTranslation.find((e) => status === e.code);
      if (message) {
        if (status === 453) {
          openResponse(453);
        } else if (status === 403 || status === 404 || status === 452) {
          openResponse(403);
        } else {
          if (message.message) {
            openResponse(message.message);
          }
        }
      } else {
        openResponse(false);
      }
      return false;
    }
  }
}
