import React from "react";
import "../../css/managerui.css";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import AxiosPost from "../../Functions/AxiosPost";
import { addItem } from "../../reducers/managersInfoSlice";

function ManagerInvitaionDialog(props) {
  const [mailAddressToInvite, setMailAddressToInvite] = React.useState("");
  const handleMailAddressToInvite = (event) => {
    setMailAddressToInvite(event.target.value);
  };

  const emailPattern =
    "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";
  const [emailError, setEmailError] = React.useState(false);
  const emailRef = React.useRef(null);
  const formValidation = () => {
    if (emailRef.current) {
      let valid = true;
      const e = emailRef.current;
      const ok = e.validity.valid;
      setEmailError(!ok);
      valid = ok;
      return valid;
    }
  };
  const inviteManager = () => {
    if (formValidation()) {
      const fetchData = async () => {
        props.setIsLoading(true);
        await AxiosPost(
          "/invite_manager_by_email",
          {
            managerEmail: mailAddressToInvite,
            groupId: props.groupId,
          },
          props.idToken,
          props.openResponseDialog
        ).then((res) => {
          if (res) {
            const addInfo = {
              mailAddress: mailAddressToInvite,
              managerId: res.data.managerId,
              managerName: res.data.managerName,
            };
            props.dispatch(addItem(addInfo));
            props.addManager(addInfo);
            props.setIsLoading(false);
          }
        });
      };
      props.closeManagerDialog();
      fetchData();
    }
  };
  return (
    <Dialog
      open={props.managerDialog}
      onClose={() => {
        props.closeManagerDialog();
        setMailAddressToInvite("");
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle sx={{ mb: -2 }}>
        <p>招待するマネージャーのメールアドレスを入力してください</p>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid container>
              <Grid item xs={9}>
                <TextField
                  id="outlined-basic"
                  value={mailAddressToInvite}
                  required
                  inputRef={emailRef}
                  inputProps={{ required: true, pattern: emailPattern }}
                  helperText={emailError && emailRef.current.validationMessage}
                  error={emailError}
                  onChange={(event) => handleMailAddressToInvite(event)}
                  label=""
                  variant="outlined"
                  sx={{ width: "100%", ml: 3 }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  sx={{ ml: 4, mt: 1 }}
                  size="small"
                  onClick={() => inviteManager()}
                >
                  <PersonAddIcon fontSize="small" sx={{ m: 1 }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          sx={{ mt: -2 }}
          onClick={() => props.closeManagerDialog()}
          autoFocus
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManagerInvitaionDialog;
