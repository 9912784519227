import React from "react";
import Grid from "@mui/material/Grid";
import MaterialTable from "material-table";
// import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import tableIcons from "../../Settings/TableIcons";
import AxiosPost from "../../Functions/AxiosPost";
import localizationJapanese from "../../Settings/ToJapanese";
import { deleteItem } from "../../reducers/managersInfoSlice";

function ManagerTable(props) {
  const [removalDialog, setRemovalDialog] = React.useState(false);
  const [managerToRemove, setManagerToRemove] = React.useState(null);
  const removeManager = (rowData) => {
    setManagerToRemove(rowData);
    setRemovalDialog(true);
  };
  const closeRemovalDialog = () => {
    setRemovalDialog(false);
  };
  const removeProcess = () => {
    props.setIsLoading(true);
    setRemovalDialog(false);
    const fetchData = async () => {
      await AxiosPost(
        "/remove_manager_from_group",
        {
          managerIdToRemove: managerToRemove.managerId,
          groupId: props.groupId,
        },
        props.idToken,
        props.openResponseDialog
      ).then((response) => {
        if (response) {
          props.dispatch(deleteItem({ managerId: managerToRemove.managerId }));
          props.deleteManager(managerToRemove.managerId);
          props.setIsLoading(false);
        }
      });
    };
    fetchData();
  };
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        icons={tableIcons}
        title="マネージャー"
        columns={managerTableColumns(removeManager)}
        data={props.managersList}
        options={{
          rowStyle: {
            backgroundColor: "#f0ffff",
          },
          tableLayout: "auto",
        }}
        localization={localizationJapanese}
      />
      {managerToRemove ? (
        <Dialog
          open={removalDialog}
          onClose={closeRemovalDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText>
              <p className="confirmText" align="center">
                {managerToRemove.managerName}を削除しますか？
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={() => removeProcess()}
            >
              OK
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => closeRemovalDialog()}
            >
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Grid></Grid>
      )}
    </div>
  );
}

function managerTableColumns(removeManager) {
  const columns = [
    {
      title: "名前",
      field: "managerName",
      type: "string",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "メールアドレス",
      field: "mailAddress",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },

    {
      title: "",
      field: "delete",
      type: "numeric",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <Grid>
          <Button
            variant="outlined"
            size="small"
            onClick={() => removeManager(rowData)}
            sx={{ mt: -0.5, mb: -0.5 }}
          >
            {/* <DeleteIcon fontSize="small" /> */}
            削除
          </Button>
        </Grid>
      ),
    },
  ];
  return columns;
}

export default ManagerTable;
