import { configureStore } from "@reduxjs/toolkit";
import idTokenReducer from "./reducers/idTokenSlice";
import groupsInfoReducer from "./reducers/groupsInfoSlice";
import groupNamesInfoReducer from "./reducers/groupNamesInfoSlice";
import driversInfoReducer from "./reducers/driversInfoSlice";
import unapprovedDriversInfoReducer from "./reducers/unapprovedDriversInfoSlice";
import managersInfoReducer from "./reducers/managersInfoSlice";
import displayedGroupIdReducer from "./reducers/displayedGroupIdSlice";
import userNameInfoReducer from "./reducers/userNameInfoSlice";

const store = configureStore({
  reducer: {
    idToken: idTokenReducer,
    groupsInfo: groupsInfoReducer,
    groupNamesInfo: groupNamesInfoReducer,
    driversInfo: driversInfoReducer,
    unapprovedDriversInfo: unapprovedDriversInfoReducer,
    managersInfo: managersInfoReducer,
    displayedGroupId: displayedGroupIdReducer,
    userNameInfo: userNameInfoReducer,
  },
});

export default store;
