import React from "react";
import "../../css/managerui.css";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import AxiosPost from "../../Functions/AxiosPost";
import toStandard from "../../Functions/ISOtoStandard";

function MedicineDialog(props) {
  const [permissionReason, setPermissionReason] = React.useState([]);
  React.useEffect(() => {
    setPermissionReason(props.dialogMedicine.map((obj) => obj.reason));
  }, [props.dialogMedicine]);
  const handlePermissionButton = (item, index) => {
    const modifiedMedicineItem = {
      driverId: props.medicineDialogInfo.driverId,
      groupId: props.groupId,
      color: props.medicineColor === "禁止薬" ? "red" : "yellow",
      addedAt: item.addedAt,
      reason: permissionReason[index],
    };
    const fetchPermission = async () => {
      const response = await AxiosPost(
        "/permit_medicine_in_group",
        modifiedMedicineItem,
        props.idToken,
        props.openResponseDialog
      ).then((response) => {
        if (response) {
          props.closeMedicineDialog();
          props.modifyMedicine(modifiedMedicineItem);
        }
      });
    };
    fetchPermission();
  };
  const handlePermissionReason = (event, index) => {
    setPermissionReason(
      permissionReason.map((obj, num) =>
        num === index ? event.target.value : obj
      )
    );
  };
  const [toModify, setToModify] = React.useState(
    new Array(props.dialogMedicine.length).fill(false)
  );
  const handleModifyReason = (event, index) => {
    setPermissionReason(
      permissionReason.map((obj, num) =>
        num === index ? event.target.value : obj
      )
    );
  };
  const handleModifyButton = (addedAt, index) => {
    const modifiedMedicineItem = {
      driverId: props.medicineDialogInfo.driverId,
      groupId: props.groupId,
      color: props.medicineColor === "禁止薬" ? "red" : "yellow",
      addedAt: addedAt,
      reason: permissionReason[index],
    };
    const fetchModify = async () => {
      await AxiosPost(
        "/modify_reason_for_permitting_medicine_in_group",
        modifiedMedicineItem,
        props.idToken,
        props.openResponseDialog
      ).then((response) => {
        if (response) {
          props.closeMedicineDialog();
          props.modifyMedicine(modifiedMedicineItem);
        }
      });
    };
    fetchModify();
  };
  const [toUndoPermission, setToUndoPermission] = React.useState(false);
  const [itemToUndo, setItemToUndo] = React.useState("");
  const openUndoDialog = (addedAt) => {
    setItemToUndo(addedAt);
    setToUndoPermission(true);
  };
  const closeUndoDialog = () => {
    setToUndoPermission(false);
  };
  const undoPermission = async () => {
    const undoItem = {
      groupId: props.groupId,
      driverId: props.medicineDialogInfo.driverId,
      addedAt: itemToUndo,
    };
    await AxiosPost(
      "/undo_medicine_permission_in_group",
      undoItem,
      props.idToken,
      props.openResponseDialog
    ).then((response) => {
      if (response) {
        props.closeMedicineDialog();
        props.modifyMedicine();
      }
    });
    // console.log(addedAt, index);
  };
  const closeDialog = () => {
    props.closeMedicineDialog();
    setToModify(toModify.fill(false));
    // setPermissionReason(permissionReason.fill(""));
    // props.dialogMedicine.map((obj) => obj.reason)
    setPermissionReason(props.dialogMedicine.map((obj) => obj.reason));
  };
  return (
    <Dialog
      open={props.medicineDialog}
      onClose={closeDialog}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        <p>
          {props.medicineDialogInfo.driverShowName}：{props.medicineColor}
        </p>
      </DialogTitle>
      <DialogContent>
        {props.dialogMedicine.map((item, index) => {
          item = { ...item, addTime: toStandard(item.addedAt) };
          return (
            <Grid container key={index} sx={{ mb: 3 }}>
              <Grid container>
                <div>
                  {index + 1}. 服薬登録日:{item.addTime}：
                </div>
                {item.confirmed ? (
                  <div className="fontBlue">許可済</div>
                ) : (
                  <div className="fontRed">未許可</div>
                )}
              </Grid>
              {item.confirmed ? (
                <Grid container>
                  <Grid sx={{ mt: -1 }}>
                    <Grid item>
                      <p>許可者：{item.managerNameOfConfirmer}</p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: -3 }}>
                    <Grid item xs={10}>
                      <p>許可理由：{item.reason}</p>
                    </Grid>
                  </Grid>
                  {!toModify[index] ? (
                    <Grid container>
                      <Grid item xs={1.5}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setToModify(
                              toModify.map((bool, num) =>
                                num === index ? true : false
                              )
                            );
                          }}
                        >
                          編集
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            openUndoDialog(item.addedAt);
                          }}
                        >
                          許可取消
                        </Button>
                        <Dialog
                          open={toUndoPermission}
                          onClose={closeUndoDialog}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogContent>
                            <DialogContentText>
                              <p className="confirmText" align="center">
                                許可を取り消しますか？
                              </p>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                undoPermission();
                              }}
                            >
                              OK
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <TextField
                            id="permissionReason"
                            value={permissionReason[index]}
                            onChange={(event) =>
                              handleModifyReason(event, index)
                            }
                            multiline
                            rows={3}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mt: 1 }}>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              handleModifyButton(item.addedAt, index)
                            }
                          >
                            変更
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        id="permissionReason"
                        value={permissionReason[index]}
                        onChange={(event) =>
                          handlePermissionReason(event, index)
                        }
                        multiline
                        rows={3}
                        label="許可理由を記入"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" className="margin5">
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        sx={{ m: 1 }}
                        onClick={() => handlePermissionButton(item, index)}
                      >
                        登録
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={() => closeDialog()}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MedicineDialog;
