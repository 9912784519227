const awsmobile = {
  aws_project_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_b1Ynx715t",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_web_client_id: "2a2lv5vrearcursc7nnslo602g",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_signup_attributes: ["PREFERRED_USERNAME", "EMAIL"],
};

export default awsmobile;
