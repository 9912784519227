import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const driversInfoSlice = createSlice({
  name: "driversInfo",
  initialState,
  reducers: {
    changeItem(state, action) {
      const data = action.payload;
      state.items = [];
      data.map((item) => {
        state.items = [...state.items, { ...item }];
      });
    },
    modifyDriverData(state, action) {
      state.items = state.items.map((item) =>
        item.driverId === action.payload.driverId
          ? {
              ...item,
              [action.payload.key]: action.payload[action.payload.key],
            }
          : { ...item }
      );
    },
    addItem(state, action) {
      state.items = [...state.items, { ...action.payload }];
    },
    deleteDriver(state, action) {
      state.items = state.items.filter(
        (item) => item.driverid !== action.payload.driverid
      );
    },
    resetDriversInfo(state, action) {
      state.items = [];
    },
  },
});

export const {
  changeItem,
  modifyDriverData,
  addItem,
  deleteDriver,
  resetDriversInfo,
} = driversInfoSlice.actions;
export default driversInfoSlice.reducer;
