import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: false,
};

const groupsInfoSlice = createSlice({
  name: "groupsInfo",
  initialState,
  reducers: {
    changeItem(state, action) {
      const data = action.payload;
      if (data[0]) {
        state.items = [];
        data.map((item) => {
          state.items = [...state.items, { ...item }];
        });
      }
    },
    modifyGroupData(state, action) {
      state.items = state.items.map((item) =>
        item.groupId === action.payload.groupId
          ? {
              ...item,
              [action.payload.key]: action.payload[action.payload.key],
            }
          : { ...item }
      );
    },
    addItem(state, action) {
      state.items = [...state.items, { ...action.payload }];
    },
    deleteItem(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    resetGroupsInfo(state, action) {
      state.items = false;
    },
  },
});

export const {
  changeItem,
  modifyGroupData,
  addItem,
  deleteItem,
  resetGroupsInfo,
} = groupsInfoSlice.actions;
export default groupsInfoSlice.reducer;
