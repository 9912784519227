import React from "react";
import Grid from "@mui/material/Grid";
import MaterialTable from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import MedicineDialog from "../Dialogs/MedicineDialog";
import ModificationDialog from "../Dialogs/ModificationDialog";
import AxiosPost from "../../Functions/AxiosPost";
import tableIcons from "../../Settings/TableIcons";
import localizationJapanese from "../../Settings/ToJapanese";
import {
  modifyDriverData,
  deleteDriver,
} from "../../reducers/driversInfoSlice";

function ApprovedDriverTable(props) {
  const [approvedDriversList] = React.useState(props.approvedDriversList);
  const [modificationDialog, setModificationDialog] = React.useState(false);
  const [descriptionToModify, setDescriptionToModify] = React.useState("");
  const [descriptionOf, setDescriptionOf] = React.useState({});
  const modifyDescription = (rowData) => {
    setDescriptionOf({
      name: rowData.driverShowName,
      content: {
        driverId: rowData.driverId,
        groupId: props.groupId,
        description: rowData.description,
      },
      operation: "description",
    });
    setDescriptionToModify(rowData.description);
    setModificationDialog(true);
  };
  const closeModificationDialog = () => {
    setModificationDialog(false);
  };
  const handleDescriptionToModify = (event) => {
    setDescriptionToModify(event.target.value);
  };
  const [medicineDialog, setMedicineDialog] = React.useState(false);
  const [medicineDialogInfo, setMedicineDialogInfo] = React.useState(null);
  const [dialogMedicine, setDialogMedicine] = React.useState(null);
  const [medicineColor, setMedicineColor] = React.useState("注意薬");
  const closeMedicineDialog = () => {
    setMedicineDialog(false);
  };
  const openMedicineDialog = (row, color) => {
    setMedicineDialogInfo(row);
    setMedicineColor(color);
    if (color === "注意薬") {
      setDialogMedicine(row.medicines.yellow);
    } else if (color === "禁止薬") {
      setDialogMedicine(row.medicines.red);
    }
    setMedicineDialog(true);
  };
  const [removalDialog, setRemovalDialog] = React.useState(false);
  const [driverToRemove, setDriverToRemove] = React.useState(null);
  const [toProcess, setToProcess] = React.useState("");
  const removeDriver = (rowData, option) => {
    setDriverToRemove(rowData);
    if (option === "absent") {
      if (rowData.isLongAbsent) {
        setToProcess("復帰");
      } else {
        setToProcess("長欠に");
      }
    } else {
      setToProcess("削除");
    }
    setRemovalDialog(true);
  };
  const closeRemovalDialog = () => {
    setRemovalDialog(false);
  };
  const process = () => {
    setRemovalDialog(false);
    props.setIsLoading(true);
    const fetchProcess = async () => {
      if (toProcess === "削除") {
        const deleteItem = {
          driverId: driverToRemove.driverId,
          groupId: props.groupId,
        };
        await AxiosPost(
          "/remove_driver_from_group",
          deleteItem,
          props.idToken,
          props.openResponseDialog
        ).then((response) => {
          if (response) {
            props.dispatch(deleteDriver(deleteItem));
            props.deleteDriver(deleteItem);
            props.setIsLoading(false);
          }
        });
      } else if (toProcess === "長欠に") {
        const modifiedItem = {
          key: "isLongAbsent",
          isLongAbsent: true,
          driverId: driverToRemove.driverId,
          groupId: props.groupId,
        };
        await AxiosPost(
          "/set_driver_long_absent",
          modifiedItem,
          props.idToken,
          props.openResponseDialog
        ).then((response) => {
          if (response) {
            props.dispatch(modifyDriverData(modifiedItem));
            props.modifyDriver(modifiedItem);
            props.setIsLoading(false);
          }
        });
      } else if (toProcess === "復帰") {
        const modifiedItem = {
          key: "isLongAbsent",
          isLongAbsent: false,
          driverId: driverToRemove.driverId,
          groupId: props.groupId,
        };
        await AxiosPost(
          "/set_driver_active",
          modifiedItem,
          props.idToken,
          props.openResponseDialog
        ).then((response) => {
          if (response) {
            props.dispatch(modifyDriverData(modifiedItem));
            props.modifyDriver(modifiedItem);
            props.setIsLoading(false);
          }
        });
      }
    };
    fetchProcess();
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        icons={tableIcons}
        title="所属ドライバー"
        columns={approvedDriverTableColumns(
          openMedicineDialog,
          modifyDescription,
          removeDriver
        )}
        data={approvedDriversList}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor: rowData.isLongAbsent ? "#EEE" : "#f0ffff",
          }),
          pageSize: 10,
          pageSizeOptions: [10, 20, 100],
        }}
        localization={localizationJapanese}
      />
      {medicineDialogInfo ? (
        <MedicineDialog
          medicineDialog={medicineDialog}
          closeMedicineDialog={closeMedicineDialog}
          medicineDialogInfo={medicineDialogInfo}
          dialogMedicine={dialogMedicine}
          medicineColor={medicineColor}
          groupId={props.groupId}
          refreshData={props.refreshData}
          modifyMedicine={props.modifyMedicine}
          idToken={props.idToken}
          openResponseDialog={props.openResponseDialog}
        />
      ) : (
        <Grid></Grid>
      )}
      <ModificationDialog
        modificationDialog={modificationDialog}
        closeModificationDialog={closeModificationDialog}
        table="driver"
        description={descriptionToModify}
        information={descriptionOf}
        handleDescriptionToModify={handleDescriptionToModify}
        modifyDriver={props.modifyDriver}
        idToken={props.idToken}
        dispatch={props.dispatch}
        openResponseDialog={props.openResponseDialog}
        setIsLoading={props.setIsLoading}
      />
      {driverToRemove ? (
        <Dialog
          open={removalDialog}
          onClose={closeRemovalDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText>
              <p className="confirmText" align="center">
                {driverToRemove.driverShowName}を{toProcess}します
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => process()}>
              OK
            </Button>
            <Button variant="outlined" onClick={() => closeRemovalDialog()}>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Grid></Grid>
      )}
    </div>
  );
}

function approvedDriverTableColumns(
  openMedicineDialog,
  modifyDescription,
  removeDriver
) {
  const columns = [
    {
      title: "ステータス",
      field: "status",
      type: "string",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return (
          <Chip
            variant="outlined"
            label={rowData.isLongAbsent ? "長欠" : "active"}
            color={rowData.isLongAbsent ? "primary" : "active"}
            onClick={() => removeDriver(rowData, "absent")}
          />
        );
      },
    },
    {
      title: "名前",
      field: "driverShowName",
      type: "string",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "社員ID",
      field: "driverShowNumber",
      type: "numeric",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "許可薬",
      field: "green",
      type: "numeric",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      sorting: false,
      render: (rowData) => {
        return (
          <Grid alignItems="flex-end">
            {rowData.isLongAbsent ? <div></div> : <div>{rowData.green}</div>}
          </Grid>
        );
      },
    },
    {
      title: "注意薬",
      field: "yellow",
      type: "numeric",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return (
          <Grid alignItems="flex-end">
            {rowData.isLongAbsent ? (
              <div></div>
            ) : rowData.yellow === 0 ? (
              <div>{rowData.yellow}</div>
            ) : (
              <Chip
                label={rowData.yellow}
                color={rowData.allYellowConfirmed ? "allowed" : "alert"}
                onClick={() => {
                  openMedicineDialog(rowData, "注意薬");
                }}
              />
            )}
          </Grid>
        );
      },
    },
    {
      title: "禁止薬",
      field: "red",
      type: "numeric",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return (
          <Grid>
            {rowData.isLongAbsent ? (
              <div></div>
            ) : rowData.red === 0 ? (
              <div>{rowData.red}</div>
            ) : (
              <Chip
                label={rowData.red}
                color={rowData.allRedConfirmed ? "allowed" : "alert"}
                onClick={() => {
                  openMedicineDialog(rowData, "禁止薬");
                }}
              />
            )}
          </Grid>
        );
      },
    },
    {
      title: "最新登録日",
      field: "updatedAt",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "メモ",
      field: "description",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return (
          <Grid container>
            <Grid container>
              <Grid item xs={11}>
                {rowData.shortDescription ? (
                  <Tooltip title={rowData.description} placement="bottom-start">
                    <div
                      onMouseEnter={() => {
                        console.log("入った");
                      }}
                      onMouseLeave={() => {
                        console.log("でた");
                      }}
                    >
                      {rowData.shortDescription}
                    </div>
                  </Tooltip>
                ) : (
                  <div>{rowData.description}</div>
                )}
              </Grid>
              <Grid item xs={1}>
                <EditIcon
                  className="pushIcon"
                  onClick={() => modifyDescription(rowData)}
                  sx={{ mt: -0.3 }}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: "",
      field: "delete",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return (
          <Grid container>
            <Grid container>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => removeDriver(rowData, "delete")}
                >
                  削除
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
  ];
  return columns;
}

export default ApprovedDriverTable;
