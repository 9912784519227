import { Auth } from "aws-amplify";
import { changeToken } from "../reducers/idTokenSlice";

export default async function getIdToken(
  idToken,
  setIdToken,
  dispatch,
  navigate
) {
  let token = idToken;
  if (token) {
    return token;
  } else {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        const newToken = user.signInUserSession.idToken.jwtToken;
        token = newToken;
        setIdToken(newToken);
        dispatch(changeToken(newToken));
      })
      .catch((e) => {
        console.log(e);
        navigate("/");
      });
    return token;
  }
}
