import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import MaterialTable from "material-table";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import EditIcon from "@mui/icons-material/Edit";
import ReactLoading from "react-loading";

import ModificationDialog from "../Dialogs/ModificationDialog";
import tableIcons from "../../Settings/TableIcons";
import GroupsInfo from "../../components/Data/GroupsInfo";
import localizationJapanese from "../../Settings/ToJapanese";

import store from "../../store";
import "../../css/managerui.css";

function GroupTable(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupList, setGroupList] = React.useState([]);
  React.useEffect(() => {
    const storeGroupList = store.getState().groupsInfo.items;
    const fetchData = async () => {
      const response = await GroupsInfo(
        props.idToken,
        dispatch,
        props.openResponseDialog
      );
      return response;
    };
    if (!groupList[0]) {
      if (storeGroupList) {
        setGroupList(storeGroupList.map((item) => ({ ...item })));
        props.setIsLoading(false);
      } else if (props.idToken) {
        fetchData().then((res) => {
          if (res.data.groups[0]) {
            setGroupList(res.data.groups.map((item) => ({ ...item })));
          } else {
            setGroupList(false);
          }
          props.setIsLoading(false);
        });
      }
    }
  }, []);
  const modifyData = (modifiedItem) => {
    setGroupList(
      groupList.map((item) =>
        item.groupId === modifiedItem.groupId
          ? { ...item, [modifiedItem.key]: modifiedItem[modifiedItem.key] }
          : { ...item }
      )
    );
  };
  const [modificationDialog, setModificationDialog] = React.useState(false);
  const [descriptionToModify, setDescriptionToModify] = React.useState("");
  const [nameToModify, setNameToModify] = React.useState("");
  const [descriptionOf, setDescriptionOf] = React.useState({});
  const modifyDescription = (rowData, option) => {
    setDescriptionOf({
      name: rowData.groupName,
      content: { groupId: rowData.groupId, description: rowData.description },
      operation: option,
    });
    setDescriptionToModify(rowData.description);
    setNameToModify(rowData.groupName);
    setModificationDialog(true);
  };
  const closeModificationDialog = () => {
    setModificationDialog(false);
  };
  const handleDescriptionToModify = (event) => {
    setDescriptionToModify(event.target.value);
  };
  const handleNameToModify = (event) => {
    setNameToModify(event.target.value);
  };
  const transferPage = (rowData, tab) => {
    navigate(
      "/drivermanagerlist/" +
        rowData.groupId +
        "/" +
        tab +
        "?name=" +
        rowData.groupName
    );
  };
  return (
    <div style={{ maxWidth: "100%" }}>
      {props.isLoading ? (
        <Backdrop open={props.isLoading}>
          <ReactLoading
            type="spin"
            color="#87cefa"
            height="100px"
            width="100px"
          />
        </Backdrop>
      ) : (
        <MaterialTable
          icons={tableIcons}
          title=""
          columns={groupTableColumns(modifyDescription, transferPage, props)}
          data={groupList ? groupList : []}
          options={{
            rowStyle: {
              backgroundColor: "#f0ffff",
            },
          }}
          localization={localizationJapanese}
        />
      )}
      <ModificationDialog
        modificationDialog={modificationDialog}
        closeModificationDialog={closeModificationDialog}
        table="group"
        idToken={props.idToken}
        description={descriptionToModify}
        name={nameToModify}
        information={descriptionOf}
        handleDescriptionToModify={handleDescriptionToModify}
        handleNameToModify={handleNameToModify}
        modifyData={modifyData}
        dispatch={dispatch}
        openResponseDialog={props.openResponseDialog}
        setIsLoading={props.setIsLoading}
      />
    </div>
  );
}

function groupTableColumns(modifyDescription, transferPage, props) {
  const columns = [
    {
      title: "グループ名",
      field: "groupName",
      type: "string",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return (
          <Grid container>
            <Grid item>
              <div>{rowData.groupName}</div>
            </Grid>
            <Grid item>
              <EditIcon
                className="pushIcon"
                onClick={() => modifyDescription(rowData, "name")}
                sx={{ mt: -0.3, ml: 1 }}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: "ID",
      field: "groupId",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "ドライバー人数",
      field: "numOfDrivers",
      type: "numeric",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return (
          <p
            className="normalPushNumber"
            onClick={() => transferPage(rowData, "driver")}
          >
            {rowData.numOfDrivers}
          </p>
        );
      },
    },
    {
      title: "マネージャー人数",
      field: "numOfManagers",
      type: "numeric",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <p
          className="normalPushNumber"
          onClick={() => transferPage(rowData, "manager")}
        >
          {rowData.numOfManagers}
        </p>
      ),
    },
    {
      title: "メモ",
      field: "description",
      headerStyle: {
        minWidth: 800,
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <Grid container>
          <Grid item>
            <div>{rowData.description}</div>
          </Grid>
          <Grid item>
            <EditIcon
              className="pushIcon"
              onClick={() => modifyDescription(rowData, "description")}
              sx={{ mt: -0.3, ml: 1 }}
            />
          </Grid>
        </Grid>
      ),
    },
  ];
  return columns;
}

export default GroupTable;
