import AxiosPost from "../../Functions/AxiosPost";
import toStandard from "../../Functions/ISOtoStandard";
import { changeItem } from "../../reducers/unapprovedDriversInfoSlice";

function UnapprovedDriversInfo(groupId, idToken, dispatch, openResponse) {
  const getData = async () => {
    const response = await AxiosPost(
      "/list_unapproved_drivers_of_group",
      {
        groupId: groupId,
      },
      idToken,
      openResponse
    );
    let rawData = response.data.drivers;
    for (let i = 0; i < rawData.length; i++) {
      rawData[i]["appliedAt"] = toStandard(rawData[i]["appliedAt"]);
    }
    dispatch(changeItem(rawData));
    return rawData;
  };
  return getData();
}

export default UnapprovedDriversInfo;
