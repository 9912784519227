import AxiosPost from "../../Functions/AxiosPost";
import { changeItem } from "../../reducers/userNameInfoSlice";

function UserInfo(idToken, dispatch, openResponse) {
  const getData = async () => {
    const response = await AxiosPost(
      "/get_user_info",
      {},
      idToken,
      openResponse
    );
    dispatch(changeItem(response.data.userName));
    return response.data.userName;
  };
  return getData();
}

export default UserInfo;
