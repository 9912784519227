import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../css/managerui.css";
import store from "../store";
import UserInfo from "../components/Data/UserInfo";
import GroupNamesInfo from "../components/Data/GroupNamesInfo";
import EditNameDialog from "../components/Dialogs/EditNameDialog";
import ResponseDialog from "../components/Dialogs/ResponseDialog";
import getIdToken from "../Functions/getIdToken";
import AxiosPost from "../Functions/AxiosPost";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import EditIcon from "@mui/icons-material/Edit";
import ReactLoading from "react-loading";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";
import vocabularies from "../Settings/AmplifyTranslation";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
Amplify.configure(awsExports);

function AccountInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDialog, setIsDialog] = React.useState(false);
  const [dialogText, setDialogText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const openDialog = (message) => {
    setDialogText(message);
    setIsDialog(true);
  };
  const closeDialog = () => {
    setIsDialog(false);
  };
  const [idToken, setIdToken] = React.useState(store.getState().idToken.item);
  const [userName, setUserName] = React.useState(
    store.getState().userNameInfo.item
  );
  const [managingGroups, setManagingGroups] = React.useState(
    store.getState().groupNamesInfo.items
  );
  React.useEffect(() => {
    const fetchUserName = async (idToken) => {
      const response = await UserInfo(idToken, dispatch, openDialog);
      setUserName(response);
      setIsLoading(false);
    };
    const fetchManagingGroups = async (idToken) => {
      const response = await GroupNamesInfo(idToken, dispatch);
      setManagingGroups(response);
      setIsLoading(false);
    };
    if (!userName || !managingGroups[0]) {
      setIsLoading(true);
      getIdToken(idToken, setIdToken, dispatch, navigate).then((idToken) => {
        if (!userName && !managingGroups[0]) {
          Promise.all([
            UserInfo(idToken, dispatch, openDialog),
            GroupNamesInfo(idToken, dispatch),
          ])
            .then((result) => {
              setUserName(result[0]);
              setManagingGroups(result[1]);
              setIsLoading(false);
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (!userName) {
          fetchUserName(idToken);
        } else if (!managingGroups[0]) {
          fetchManagingGroups(idToken);
        }
      });
    }
  }, [idToken]);
  const [isEditable, setIsEditable] = React.useState(false);
  const closeEditDialog = () => {
    setIsEditable(false);
  };
  const editName = () => {
    setIsEditable(true);
  };
  const changeName = async (inputName) => {
    setIsEditable(false);
    setIsLoading(true);
    await AxiosPost(
      "/change_user_name",
      { newUserName: inputName },
      idToken,
      openDialog
    ).then((response) => {
      if (response) {
        setUserName(inputName);
      }
      setIsLoading(false);
    });
  };
  return (
    <div>
      <Authenticator>
        {({ user }) => {
          return (
            <div>
              <Grid container justifyContent="center">
                {isLoading ? (
                  <Backdrop open={isLoading}>
                    <ReactLoading
                      type="spin"
                      color="#87cefa"
                      height="100px"
                      width="100px"
                    />
                  </Backdrop>
                ) : (
                  <Card sx={{ maxWidth: 1000, minWidth: 1000, mt: 3 }}>
                    <h2 className="accountInfo">登録情報</h2>
                    <Grid container sx={{ mt: -2, mb: 5 }}>
                      <Grid item xs={4}>
                        <h4 className="accountInfo_name">名前</h4>
                        <h4 className="accountInfo">メールアドレス</h4>
                        <h4 className="accountInfo">管理グループ</h4>
                      </Grid>
                      <Grid item xs={8}>
                        <h2></h2>
                        <h4 className="accountInfo">
                          {userName}
                          <EditIcon
                            className="pushIcon"
                            sx={{ mb: -0.5, ml: 1 }}
                            onClick={() => editName()}
                          />
                        </h4>
                        <h4 className="accountInfo">{user.attributes.email}</h4>
                        {managingGroups.map((item) => {
                          return (
                            <h4 className="groupNames">・{item.groupName}</h4>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </Grid>
              <EditNameDialog
                isEditable={isEditable}
                closeEditDialog={closeEditDialog}
                preName={userName}
                changeName={changeName}
              />
              <ResponseDialog
                isDialog={isDialog}
                closeDialog={closeDialog}
                dialogText={dialogText}
                setIsLoading={setIsLoading}
              />
            </div>
          );
        }}
      </Authenticator>
    </div>
  );
}

export default AccountInfo;

I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");
