import AxiosPost from "../../Functions/AxiosPost";
import toStandard from "../../Functions/ISOtoStandard";
import { changeItem } from "../../reducers/driversInfoSlice";
import { changeId } from "../../reducers/displayedGroupIdSlice";

function ApprovedDriversInfo(groupId, idToken, dispatch, openResponseDialog) {
  const getData = async () => {
    const response = await AxiosPost(
      "/list_approved_drivers_of_group",
      {
        groupId: groupId,
      },
      idToken,
      openResponseDialog
    );
    let rawData = response.data.drivers;
    for (let i = 0; i < rawData.length; i++) {
      rawData[i]["green"] = rawData[i]["medicines"]["green"].length;
      rawData[i]["yellow"] = rawData[i]["medicines"]["yellow"].length;
      rawData[i]["red"] = rawData[i]["medicines"]["red"].length;
      rawData[i]["updatedAt"] = toStandard(rawData[i]["updatedAt"]);
      rawData[i]["allYellowConfirmed"] = true;
      rawData[i]["allRedConfirmed"] = true;
      if (rawData[i]["description"].length > 10) {
        rawData[i]["shortDescription"] =
          rawData[i]["description"].slice(0, 10) + "...";
      }
      if (rawData[i]["yellow"]) {
        for (let j = 0; j < rawData[i]["yellow"]; j++) {
          if (!rawData[i]["medicines"]["yellow"][j]["confirmed"]) {
            rawData[i]["allYellowConfirmed"] = false;
            break;
          }
        }
      }
      if (rawData[i]["red"]) {
        for (let j = 0; j < rawData[i]["red"]; j++) {
          if (!rawData[i]["medicines"]["red"][j]["confirmed"]) {
            rawData[i]["allRedConfirmed"] = false;
            break;
          }
        }
      }
    }
    rawData.sort((a, b) => a.isLongAbsent - b.isLongAbsent);
    dispatch(changeId(groupId));
    dispatch(changeItem(rawData));
    return rawData;
  };
  return getData();
}

export default ApprovedDriversInfo;
