import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../css/managerui.css";
import store from "../store";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Backdrop from "@mui/material/Backdrop";
import ReactLoading from "react-loading";

import ApprovedDriverTable from "../components/Tables/ApprovedDriverTable";
import UnapprovedDriverTable from "../components/Tables/UnapprovedDriverTable";
import ManagerTable from "../components/Tables/ManagerTable";
import ManagerInvitationDialog from "../components/Dialogs/ManagerInvitationDialog";
import ResponseDialog from "../components/Dialogs/ResponseDialog";

import ApprovedDriversInfo from "../components/Data/ApprovedDriversInfo";
import UnapprovedDriversInfo from "../components/Data/UnapprovedDriversInfo";
import ManagersInfo from "../components/Data/ManagersInfo";
import getIdToken from "../Functions/getIdToken";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";
import vocabularies from "../Settings/AmplifyTranslation";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
Amplify.configure(awsExports);

function DriverManagerList() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = location.pathname.split("/");
  const groupId = path[2];
  const tab = path[3];
  const query = new URLSearchParams(location.search);
  const groupName = query.get("name");
  const [tabValue, setTabValue] = React.useState(tab);
  const [isLoading, setIsLoading] = React.useState(true);
  const [managersList, setManagersList] = React.useState([]);
  const [approvedDriversList, setApprovedDriversList] = React.useState([]);
  const [unapprovedDriversList, setUnapprovedDriversList] = React.useState([]);
  const [managerDialog, setManagerDialog] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(0);
  const [idToken, setIdToken] = React.useState(store.getState().idToken.item);
  const [isResponseDialog, setIsResponseDialog] = React.useState(false);
  const [responseDialogText, setResponseDialogText] = React.useState("");
  const openResponseDialog = (message) => {
    setResponseDialogText(message);
    setIsResponseDialog(true);
  };
  const closeResponseDialog = () => {
    setIsResponseDialog(false);
  };
  React.useEffect(() => {
    const getData = () => {
      getIdToken(idToken, setIdToken, dispatch, navigate).then((token) => {
        Promise.all([
          ApprovedDriversInfo(groupId, token, dispatch, openResponseDialog),
          UnapprovedDriversInfo(groupId, token, dispatch, openResponseDialog),
          ManagersInfo(groupId, token, dispatch, openResponseDialog),
        ])
          .then((result) => {
            setApprovedDriversList(result[0]);
            setUnapprovedDriversList(result[1]);
            setManagersList(result[2]);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    };
    const displayedGroupId = store.getState().displayedGroupId.item;
    const storeApprovedDriversList = store.getState().driversInfo.items;
    const storeUnapprovedDriversList =
      store.getState().unapprovedDriversInfo.items;
    const storeManagersList = store.getState().managersInfo.items;
    // ↓unapprovedDriversList[0]がtrueの場合ってあるよね(approvedも一応そうだし)
    if (!approvedDriversList[0] && !managersList[0]) {
      if (displayedGroupId && groupId !== displayedGroupId) {
        setIsLoading(true, getData());
      } else if (storeApprovedDriversList[0] && storeManagersList[0]) {
        setApprovedDriversList(
          storeApprovedDriversList.map((item) => ({ ...item }))
        );
        setUnapprovedDriversList(
          storeUnapprovedDriversList.map((item) => ({ ...item }))
        );
        setManagersList(storeManagersList.map((item) => ({ ...item })));
        setIsLoading(false);
      } else {
        getData();
      }
    } else if (groupId !== displayedGroupId) {
      setIsLoading(true, getData());
    } else {
      console.log("what is this condition");
      setIsLoading(false);
    }
  }, [refreshKey, groupName]);
  const refreshData = () => {
    setIsLoading(true);
    setRefreshKey((old) => old + 1);
  };
  const refreshApprovedDrivers = async () => {
    await ApprovedDriversInfo(groupId, idToken, dispatch).then((response) => {
      setApprovedDriversList(response);
      setIsLoading(false);
    });
  };
  const modifyUnapprovedDriver = async (modifiedItem) => {
    if (modifiedItem.process === "approve") {
      await ApprovedDriversInfo(groupId, idToken, dispatch).then((res) => {
        setApprovedDriversList(res);
        setUnapprovedDriversList(
          unapprovedDriversList.filter(
            (item) => item.driverId !== modifiedItem.driverId
          )
        );
      });
    } else if (modifiedItem.process === "reject") {
      setUnapprovedDriversList(
        unapprovedDriversList.filter(
          (item) => item.driverId !== modifiedItem.driverId
        )
      );
    }
    setIsLoading(false);
  };
  const modifyDriverData = (modifiedItem) => {
    setApprovedDriversList(
      approvedDriversList.map((item) =>
        item.driverId === modifiedItem.driverId
          ? { ...item, [modifiedItem.key]: modifiedItem[modifiedItem.key] }
          : { ...item }
      )
    );
  };
  const deleteDriverData = (deleteItem) => {
    setApprovedDriversList(
      approvedDriversList.filter(
        (item) => item.driverId !== deleteItem.driverId
      )
    );
  };
  const modifyMedicineData = (modifiedMedicine) => {
    setIsLoading(true, refreshApprovedDrivers());
    // const color = modifiedMedicine.color;
    // const driver = approvedDriversList.filter(
    //   (item) => item.driverId === modifiedMedicine.driverId
    // );
    // const medicine = driver[0].medicines[color].map((item) =>
    //   item.addedAt === modifiedMedicine.addedAt
    //     ? {
    //         ...item,
    //         confirmed: true,
    //         managerNameOfConfirmer: "self",
    //         reason: modifiedMedicine.reason,
    //       }
    //     : { ...item }
    // );
    // setApprovedDriversList(
    //   approvedDriversList.map((item) =>
    //     item.driverId === modifiedMedicine.driverId ? medicine : item
    //   )
    // );
  };
  const openManagerDialog = () => {
    setManagerDialog(true);
  };
  const closeManagerDialog = () => {
    setManagerDialog(false);
  };
  const addManager = (addItem) => {
    setManagersList([...managersList, addItem]);
  };
  const deleteManager = (deleteId) => {
    setManagersList(managersList.filter((item) => item.managerId !== deleteId));
  };
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
    window.history.pushState(
      {},
      "",
      "/drivermanagerlist/" + groupId + "/" + newValue + "?name=" + groupName
    );
  };

  return (
    <Authenticator>
      <ResponseDialog
        isDialog={isResponseDialog}
        openDialog={openResponseDialog}
        closeDialog={closeResponseDialog}
        dialogText={responseDialogText}
        setIsLoading={setIsLoading}
      />
      <Grid container className="container">
        <Grid container>
          <Grid item xs={4} sx={{ ml: 2, mb: -5 }}>
            <Box sx={{ minWidth: "500px" }}>
              <p className="groupName">
                {groupName} (ID: {groupId})
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container className="container">
          <Grid item xs={12}>
            <TabContext value={tabValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  minWidth: "1000px",
                  width: "100%",
                }}
              >
                <TabList onChange={tabChange}>
                  <Tab label="ドライバーリスト" value="driver" />
                  <Tab label="マネージャーリスト" value="manager" />
                </TabList>
              </Box>
              <TabPanel value="driver">
                {isLoading ? (
                  <Backdrop open={isLoading}>
                    <ReactLoading
                      type="spin"
                      color="#87cefa"
                      height="100px"
                      width="100px"
                    />
                  </Backdrop>
                ) : (
                  <Grid container>
                    <Box
                      component="span"
                      sx={{
                        width: "100%",
                        minWidth: "950px",
                      }}
                    >
                      <Grid
                        container
                        className="unapprovedTable"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<p className="fontSize10">▼</p>}
                            >
                              <Typography>未承認ドライバー</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <UnapprovedDriverTable
                                groupId={groupId}
                                unapprovedDriversList={unapprovedDriversList}
                                refreshData={refreshData}
                                modifyUnapprovedDriver={modifyUnapprovedDriver}
                                idToken={idToken}
                                openResponseDialog={openResponseDialog}
                                setIsLoading={setIsLoading}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <ApprovedDriverTable
                            groupId={groupId}
                            approvedDriversList={approvedDriversList}
                            refreshData={refreshData}
                            modifyDriver={modifyDriverData}
                            deleteDriver={deleteDriverData}
                            modifyMedicine={modifyMedicineData}
                            idToken={idToken}
                            dispatch={dispatch}
                            openResponseDialog={openResponseDialog}
                            setIsLoading={setIsLoading}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </TabPanel>
              <TabPanel value="manager">
                <Grid container>
                  <Box
                    component="span"
                    sx={{
                      width: "100%",
                      minWidth: "1000px",
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ m: 2 }}
                          size="medium"
                          onClick={() => {
                            openManagerDialog();
                          }}
                        >
                          <PersonAddIcon fontSize="small" sx={{ m: 1 }} />
                          <p className="buttonText">招待する</p>
                        </Button>
                      </Grid>
                    </Grid>
                    <ManagerInvitationDialog
                      groupId={groupId}
                      managerDialog={managerDialog}
                      managersInfo={managersList}
                      refreshData={refreshData}
                      addManager={addManager}
                      closeManagerDialog={closeManagerDialog}
                      idToken={idToken}
                      dispatch={dispatch}
                      openResponseDialog={openResponseDialog}
                      setIsLoading={setIsLoading}
                    />
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        {isLoading ? (
                          <Backdrop open={isLoading}>
                            <ReactLoading
                              type="spin"
                              color="#87cefa"
                              height="100px"
                              width="100px"
                            />
                          </Backdrop>
                        ) : (
                          <Grid container justifyContent="center">
                            <Grid item xs={12}>
                              <ManagerTable
                                groupId={groupId}
                                managersList={managersList}
                                refreshData={refreshData}
                                deleteManager={deleteManager}
                                idToken={idToken}
                                dispatch={dispatch}
                                openResponseDialog={openResponseDialog}
                                setIsLoading={setIsLoading}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    </Authenticator>
  );
}
export default DriverManagerList;

I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");
