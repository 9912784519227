const errorCodeTranslation = [
  { code: 452, message: "情報の登録または取得に失敗しました" },
  { code: 453, message: "アクセス権がありません" },
  { code: 454, message: "グループに存在しないマネージャーです" },
  { code: 455, message: "マネージャーが存在しません" },
  { code: 456, message: "アクセス中のアカウントは操作できません" },
  { code: 457, message: "グループに存在しないドライバーです" },
  { code: 458, message: "ドライバーが服用していない薬です" },
  { code: 459, message: "既に存在しているマネージャーです" },
  { code: 460, message: "既に承認されたドライバーです" },
  { code: 461, message: "既に拒否されたドライバーです" },
  { code: 462, message: "既にactive状態のドライバーです" },
  { code: 463, message: "既に長欠状態のドライバーです" },
  { code: 464, message: "理由が記入されていません" },
  { code: 465, message: "グループ名が記入されていません" },
  { code: 466, message: "アクセス中のアカウントは操作できません" },
];

export default errorCodeTranslation;
