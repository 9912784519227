import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: "",
};

const idTokenSlice = createSlice({
  name: "idToken",
  initialState,
  reducers: {
    changeToken(state, action) {
      state.item = action.payload;
    },
    deleteToken(state, action) {
      state.item = "";
    },
  },
});

export const { changeToken, deleteToken } = idTokenSlice.actions;
export default idTokenSlice.reducer;
